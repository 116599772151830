.nav-root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.page-root {
  flex: 1;
  padding: 32px;
  overflow-x: auto;
  display: grid;
  grid-template-columns: auto 1100px auto 0px;
}

.panel {
  grid-column: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-root.ai-assistant-open {
  grid-template-columns: 0px 1100px 24px auto;
}

.MuiIconButton-root.ai-assistant-switch:hover {
  background-color: rgba(255, 255, 255, 0.5);
}
