.App {
  flex: 1;
  display: flex;
  background-color: #f5f5f5;
}

.centered-content-root {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.root-card {
  width: 1100px;
  padding: 32px;
}

.notistack-Snackbar .MuiIconButton-root {
  color: inherit;
}

.row {
  display: flex;
  align-items: center;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.space-evenly {
  justify-content: space-evenly;
}

.space-between {
  justify-content: space-between;
}

.align-start {
  align-items: flex-start;
}

.self-stretch {
  align-self: stretch;
}

.margin-bottom {
  margin-bottom: 16px !important;
}

.big-margin-bottom {
  margin-bottom: 32px !important;
}

.margin-top {
  margin-top: 16px !important;
}

.small-margin-top {
  margin-top: 8px !important;
}

.big-margin-top {
  margin-top: 32px !important;
}

.margin-left {
  margin-left: 16px !important;
}

.text-with-returns {
  white-space: pre-line;
}

.text-center {
  text-align: center;
}

.padding {
  padding: 16px;
}

.flex1 {
  flex: 1;
}

.project-modal-root {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.project-modal-paper {
  padding: 32px;
  min-height: 450px;
  min-width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.empty {
  width: 450px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.empty .MuiTypography-root {
  white-space: pre-line;
}

@media (max-width: 1200px) {
  .root-card {
    width: 900px;
    padding: 16px;
  }
}

a {
  color: blue;
}

a.attachment {
  color: #333;
  font-weight: bold;
  text-decoration: none;
}
