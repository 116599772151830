.dashboard-no-project {
  width: 450px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.dashboard-no-project .MuiTypography-root {
  white-space: pre-line;
}

.dashboard-figures {
  width: 1100px;
  display: flex;
  justify-content: space-between;
  margin: 32px 0 48px 0;
}

.assistant-cta-text {
  white-space: pre-line;
  max-width: 40%;
}

@media (max-width: 1200px) {
  .dashboard-figures {
    width: 900px;
    margin: 16px 0 24px 0;
  }
}
