.anonymous-root {
  background-color: #74a538;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.anonymous-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 30rem;
  height: 45rem;
  text-align: center;
  padding: 0 4rem;
}

.anonymous-card .MuiTypography-root {
  white-space: pre-line;
}

.anonymous-logo {
  width: 22rem;
}

.anonymous-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
}
