.forms-type-card {
  width: 170px;
  height: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.6;
}

.forms-type-empty {
  width: 170px;
  height: 170px;
}

.forms-type-card:hover {
  opacity: 1;
}

.forms-type-row {
  display: flex;
  width: 574px;
  justify-content: space-between;
}

.family-selector {
  display: flex;
  flex-direction: column;
  align-items: center;
}
