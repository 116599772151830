.project-forms-header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.project-forms-empty {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-height: 400px;
  padding: 64px 0;
}

.project-operations-label {
  flex: 1;
}

.project-operations-viewer {
  margin-bottom: 24px;
  padding: 2px;
}

.form-edit .project-modal-paper {
  max-width: 850px;
}

.form-edit-title,
.form-edit-choose-button {
  margin-bottom: 16px !important;
}

.form-edit-main {
  flex: 1;
  max-height: 670px;
  overflow-y: auto;
}

.form-edit-main .MuiFormControl-root {
  margin: 16px 0;
}

.form-chosen-title {
  margin-right: 16px;
  flex: 1;
}

.operation-fields {
  margin-bottom: 16px !important;
  text-align: center;
}

.replacement-or-not {
  text-align: center;
}

@media (max-height: 1000px) {
  .form-edit-main {
    max-height: 550px;
  }
}
