.profile-section.first {
  margin-top: 8px;
}

.profile-section {
  display: flex;
  flex-direction: row;
}

.profile-section-title {
  flex: 1;
}

.profile-form-root {
  flex: 2;
}

.profile-form-root .profile-form-row,
.profile-divider.MuiDivider-root {
  margin-bottom: 24px;
}

.profile-form-row {
  display: flex;
  flex-direction: row;
}

.profile-form-row > .not-only-textfield {
  margin-left: 16px;
  flex: 1;
}

.profile-button-container {
  display: flex;
  justify-content: center;
}

.profile-charter-button {
  align-self: center;
  margin-bottom: 32px;
}
