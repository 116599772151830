.project-card {
  display: flex;
  flex-direction: column;
}

.project-header {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  width: 100%;
}

.project-header :first-child {
  flex: 1;
}

.project-section-root {
  width: 700px;
  min-height: 350px;
  display: flex;
  flex-direction: column;
}

.project-section-root .MuiListItemSecondaryAction-root {
  right: 0 !important;
}

.row.project-tabs-root {
  justify-content: space-evenly;
  align-items: stretch;
}

.row.project-tabs-root .MuiTabs-vertical {
  height: unset;
}

.project-nav-root {
  flex: 1;
  display: flex;
  justify-content: center;
}

.cancel-modal {
  width: 550px;
}

.cancel-modal .MuiTypography-root,
.charter-details-form .MuiTypography-root {
  text-align: center;
}

.charter-details-form {
  max-width: 550px;
}

.charter-details-form .MuiTextField-root {
  margin-bottom: 16px !important;
}

.check-signature-button {
  margin-top: 16px !important;
}

.signer-form-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
}

.signer-form-row > .not-only-textfield {
  margin-left: 16px;
  flex: 1;
}

.signer-details {
  width: 700px;
}

.signer-form-info {
  margin-bottom: 8px;
}

.signer-root {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-self: stretch;
}

.signer-details-root {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.signer-details-readonly {
  flex: 1;
}

.convention-buttons {
  margin: 0 150px;
}
