.project-name-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.project-name-client {
  width: 500px;
}

.project-name-siret {
  margin: 16px 0 !important;
}

.project-name-client-form {
  display: flex;
  flex-direction: column;
  padding-top: 16px;
}

.project-name-client-form-row {
  display: flex;
  flex-direction: row;
  padding: 8px 0;
}

.project-name-client-form-row > .first-textfield {
  flex: 1;
}

.project-name-client-form-row > .not-only-textfield,
.project-name-client-form-row > .not-only-textfield-small {
  margin-left: 16px;
}
.project-name-client-form-row > .not-only-textfield {
  flex: 1;
}

.project-name-button {
  margin-top: 8px;
}
