.project-details-root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-sector-selector {
  width: 100%;
  padding: 24px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-sector-label,
.project-sector-dates,
.project-details-info,
.project-details-warning-card {
  margin-bottom: 16px !important;
}

.project-main-sector-radios {
  margin-bottom: 8px !important;
}

.project-details-warning-card {
  padding: 16px;
  text-align: "justify";
}

.project-details-warning {
  display: flex;
  align-items: center;
  text-decoration: underline;
}

.two-fields-row {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  margin-bottom: 16px;
}

.left-field,
.right-field {
  flex: 1;
}

.left-field {
  margin-right: 8px !important;
}

.right-field {
  margin-left: 8px !important;
}
