.signature-root {
  background-color: #74a538;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.signature-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  min-width: 80%;
  min-height: 800px;
  text-align: center;
  padding: 2rem;
}

.signature-info {
  flex: 1;
}

.signature-title.MuiTypography-root {
  margin-top: 64px !important;
}

.signature-logo {
  width: 16rem;
}

.signature-iframe {
  width: 950px;
}
