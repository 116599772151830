.aiContainer {
  grid-column: 4;
  height: 100%;
  max-height: calc(100vh - (64px + 48px));
  background: #fff;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-weight: 900;
  border-radius: 4px;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
    0px 1px 8px 0px rgba(0, 0, 0, 0.12);

  position: sticky;
}

.hidden {
  display: none;
}

.aiContainer .content {
  overflow-x: auto;
  padding: 0 16px;
}

.aiContainer .header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aiContainer .header img {
  margin-top: 32px;
  margin-bottom: 16px;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
}

.aiContainer .header .title {
  margin: 0 16px;
}

.aiContainer .header .subtitle {
  font-style: italic;
  text-align: center;
  margin: 0 16px;
}

.aiContainer .header .suggestedQuestionsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding-top: 16px;
}

.aiContainer .header .suggestedQuestionsContainer .suggestedQuestion {
  padding: 2px 8px;
  text-transform: none;
}

.aiContainer .subscription {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 350px 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 20;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.aiContainer .subscription-overlay {
  padding: 32px;
  max-width: 80%;
  white-space: pre-line;
}

.conversation {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;
}

.conversation .qa-container {
  padding: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}

.conversation .qa-container .question,
.conversation .qa-container .answer {
  max-width: 90%;
  word-wrap: break-word;
  display: flex;
  gap: 8px;
}

.conversation .qa-container .question {
  align-self: flex-start;
  margin-bottom: 16px;
  white-space: pre-line;
}

.conversation .qa-container .answer {
  align-self: flex-end;
  font-style: italic;
  text-align: justify;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.conversation .qa-container .answer p,
.conversation .qa-container .answer ol,
.conversation .qa-container .answer ul {
  margin: 0;
}

.aiContainer .inputContainer {
  background: #74a538;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px -3px 5px rgba(0, 0, 0, 0.25);
  padding: 16px 16px 8px 16px;
  flex-direction: column;
  gap: 8px;
}

.aiContainer .inputContainer .MuiInputBase-multiline {
  padding-top: 0;
  padding-bottom: 0;
}

.aiContainer .inputContainer .aiInput {
  border-radius: 8px;
  background-color: #fff;
  border: 0;
  max-height: 400px;
  overflow-y: auto;
}

.aiContainer .inputContainer textarea {
  padding-top: 16px;
  padding-bottom: 16px;
}

.textAndButtonContainer {
  display: flex;
  width: 100%;
  align-items: center;
}

.warning {
  color: white;
  font-size: 10px !important;
  font-style: italic;
  text-align: center;
  line-height: 12px !important;
  margin: 0 32px !important;
}

.aiButton svg {
  fill: #fff;
}
